import React, { useState } from 'react';
import axios from '../../api/api';
import { toast } from 'sonner';
import { useNavigate, useParams } from 'react-router-dom';

const AddMemberInvite = () => {
  
  const [member_email, setMember_email] = useState('');
  const [role, setRole] = useState('MEMBER');
  const navigate = useNavigate();
  const { id } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`/organizations/${id}/invites`,
        { member_email, role }
      );
      toast.success('Member invited successfully!');
      navigate(`/app/vieworganisation/${id}`);
    } catch (error) {
      console.log(error);
      toast.error('Member Not found or failed to invite member');
    }
  };

  return (
    <div className='mx-auto p-4 flex justify-center'>
      <div className='p-4 bg-white rounded-lg w-full lg:w-2/3 xl:w-1/2'>
        <h3 className='text-xl text-center font-bold text-[#8144E5]'>Send Invite to Member</h3>
        <form onSubmit={handleSubmit}>
          <div className='mt-2'>
            <label htmlFor='name'>Member Email
              <input
                type='email'
                id='name'
                required
                placeholder='user@gmail.com'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                value={member_email}
                onChange={(e) => setMember_email(e.target.value)}
              />
            </label>
          </div>
          <div className='mt-2'>
            <label htmlFor='role'>Member Role
              <select
                name='role' id='role'
                className='px-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] w-full rounded-md focus:ring-1'
                required
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value=''>Choose Role</option>
                <option value='ADMIN'>Admin</option>
                <option value='MEMBER'>Member</option>
                <option value='AGENT'>Agent</option>
              </select>
            </label>
          </div>
          <div className='py-3'>
            <button type='submit' className='bg-[#8144E5] text-white px-5 py-1 w-full hover:bg-[#6735b7]'>Invite Member</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddMemberInvite;
