import React, { useState, useEffect } from 'react';
import axios from '../../api/api';
import { useParams } from 'react-router-dom';
import GetOrganisationMember from './GetOrganisationMembers';
import OrganisationAdverts from './OrganisationAdverts';
import OrganisationDocuments from './OrganisationDocuments';
import OrganisationWallet from './OrganisationWallet'
import OrganisationCamapignMetrics from './OrganisationCamapignMetrics'
import GetOrgMemberInvites from './GetOrgMemberInvites';

const ViewMyOrganisation = () => {

  const [viewOrganisation, setViewOrganisation] = useState('');
  const { id } = useParams();

  // gettingorganisation details by id
  useEffect(() => {
    const getOrganisationById = async () => {
      try {
        const response = await axios.get(`/organizations/${id}`);
        setViewOrganisation(response.data);
      } catch (error) {
        console.error('Error fetching organisation:', error);
      }
    };
    getOrganisationById();
  }, [id]);

  return (
    <div className='mx-auto p-4'>
      <section className='bg-white rounded-lg p-4 mb-4  '>
        <h3 className='text-center text-[#8144E5] font-bold mb-4'><u>Organization Name : {viewOrganisation.name}</u></h3>
        <div className='grid grid-cols-2 gap-4 py-2 md:grid-cols-3 2xl:grid-cols-5'>
          <div>
            <span className='font-bold'>Decription : </span>
            {viewOrganisation.description}
          </div>
          <div>
            <span className='font-bold'>Registration No : </span>
            {viewOrganisation.registration_number}
          </div>
          <div>
            <span className='font-bold'>Registered Address : </span>{
            viewOrganisation.registered_address}
          </div>
          <div>
            <span className='font-bold'>Phone Number : </span>
            {viewOrganisation.contact_msisdn}
          </div>
          <div>
            <span className='font-bold'>Verification  : </span>
            {viewOrganisation.is_verified === true ? (
              <span className='bg-[#D9F8EB] text-[#2A8B4F] px-4 py-1 font-bold rounded-full'>Verified</span>
            ) : (
              <span className='bg-[#D9E7FA] text-[#215CB1] font-bold px-3 py-1 rounded-full'>Pending</span>
            )}
          </div>
          <div>
            <span className='font-bold'>Email Address : </span>
            {viewOrganisation.contact_email}
          </div>
          <div>
            <span className='font-bold'>Purpose of Account : </span>
            {viewOrganisation.purpose_of_account}
          </div>
          <div>
            <span className='font-bold'>Created By : </span>
            {viewOrganisation.created_by_user_id}
          </div>
          <div>
            <span className='font-bold'>Verified By : </span>
            {viewOrganisation.verified_by_user_id}
          </div>
          <div>
            <span className='font-bold'>Suspension : </span>
            {viewOrganisation.is_suspended === true ? (
              <span className='bg-[#FDE6D8] text-[#AC5A2B] px-3 py-1 font-bold rounded-full'>Suspended</span>
            ): (
              <span className='bg-[#D9F8EB] text-[#2A8B4F] font-bold rounded-full px-3 py-1'>UnSuspended</span>
            )}
          </div>
          <div>
            <span className='font-bold'>Created At : </span>
            {viewOrganisation.time_created ? new Date(viewOrganisation.time_created).toISOString().replace('T', ' ').slice(0, 19) : '...'}
          </div>
        </div>
      </section>

      <div role="tablist" className="tabs tabs-bordered">
        {/* organisation adverts */}
        <input type="radio" name="my_tabs_1" role="tab" className="tab checked:font-bold checked:text-[#8144E5]" aria-label="Adverts" defaultChecked />
        <div role="tabpanel" className="tab-content">
          <OrganisationAdverts />
        </div>

        {/* organisation members */}
        <input type="radio" name="my_tabs_1" role="tab" className="tab checked:font-bold checked:text-[#8144E5]" aria-label="Members" />
        <div role="tabpanel" className="tab-content">
          <GetOrganisationMember />
        </div>

        {/* organisation members invites */}
        <input type="radio" name="my_tabs_1" role="tab" className="tab whitespace-nowrap checked:font-bold checked:text-[#8144E5]" aria-label="Member Invites" />
        <div role="tabpanel" className="tab-content">
          <GetOrgMemberInvites />
        </div>

        {/* organisation documents */}
        <input type="radio" name="my_tabs_1" role="tab" className="tab checked:font-bold checked:text-[#8144E5]" aria-label="Documents" />
        <div role="tabpanel" className="tab-content">
          <OrganisationDocuments />
        </div>

        {/* Organisation wallet */}
        <input type="radio" name="my_tabs_1" role="tab" className="tab checked:font-bold checked:text-[#8144E5]" aria-label="Wallet" />
        <div role="tabpanel" className="tab-content">
          <OrganisationWallet/>
        </div>

        {/* Organisation metrics */}
        <input type="radio" name="my_tabs_1" role="tab" className="tab  whitespace-nowrap checked:font-bold checked:text-[#8144E5]" aria-label="Camapign Metrics" />
        <div role="tabpanel" className="tab-content">
          <OrganisationCamapignMetrics/>
        </div>
      </div>
    </div>
  );
};

export default ViewMyOrganisation;
