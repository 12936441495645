import React from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineKeyboardArrowDown, MdOutlineDashboard, MdAccountBalance } from 'react-icons/md';
import { RiAdvertisementFill, RiAdvertisementLine } from 'react-icons/ri';
import { IoDocuments } from 'react-icons/io5';

const AdvertisorNav = () => {
  return (
    <div className='overflow-y-auto'>
      <div id='sidenavSecExample'>
        <ul className='relative px-0.5'>
          <li className='relative'>
            <Link to='/app/dashboard' className='flex items-center text-sm py-4 px-6 xl:h-10 2xl:h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>
              <span className='w-4 h-4 mr-2'><MdOutlineDashboard /></span>
              <span>DashBoard</span>
            </Link>
          </li>
          <li className='relative' id='sidenavSecEx8'>
            <Link to='#' className='flex items-center text-sm py-4 px-6 xl:h-10 2xl:h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out cursor-pointer' data-mdb-ripple='true' data-mdb-ripple-color='primary' data-bs-toggle='collapse' data-bs-target='#collapseSidenavSecEx8' aria-expanded='false' aria-controls='collapseSidenavSecEx8'>
              <span className='w-4 h-4 mr-2'><MdAccountBalance /></span>
              <span>My Organisations</span>
              <span className='w-3 h-3 ml-auto mb-2 text-xl'><MdOutlineKeyboardArrowDown /></span>
            </Link>
            <ul className='relative accordion-collapse collapse' id='collapseSidenavSecEx8' aria-labelledby='sidenavSecEx8' data-bs-parent='#sidenavSecExample'>
              <li className='relative'>
                <Link to='/app/myorganisations' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>Organisations</Link>
              </li>
              <li className='relative'>
                <Link to='/app/addorganisation' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>Add Organisation</Link>
              </li>
            </ul>
          </li>
          <li className='relative' id='sidenavSecEx2'>
            <Link to='#' className='flex items-center text-sm py-4 px-6 xl:h-10 2xl:h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out cursor-pointer' data-mdb-ripple='true' data-mdb-ripple-color='primary' data-bs-toggle='collapse' data-bs-target='#collapseSidenavSecEx2' aria-expanded='false' aria-controls='collapseSidenavSecEx2'>
              <span className='w-4 h-4 mr-2'><RiAdvertisementFill /></span>
              <span>Adverts</span>
              <span className='w-3 h-3 ml-auto mb-2 text-xl'><MdOutlineKeyboardArrowDown /></span>
            </Link>
            <ul className='relative accordion-collapse collapse' id='collapseSidenavSecEx2' aria-labelledby='sidenavSecEx2' data-bs-parent='#sidenavSecExample'>
              <li className='relative'>
                <Link to='/app/listadvert' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>List Adverts</Link>
              </li>
              <li className='relative'>
                <Link to='/app/listcategories' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>List Categories</Link>
              </li>
            </ul>
          </li>
          <li className='relative' id='sidenavSecEx5'>
            <Link to='#' className='flex items-center text-sm py-4 px-6 xl:h-10 2xl:h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out cursor-pointer' data-mdb-ripple='true' data-mdb-ripple-color='primary' data-bs-toggle='collapse' data-bs-target='#collapseSidenavSecEx5' aria-expanded='false' aria-controls='collapseSidenavSecEx5'>
              <span className='w-4 h-4 mr-2'><RiAdvertisementLine /></span>
              <span>Advert Campaign</span>
              <span className='w-3 h-3 ml-auto mb-2 text-xl'><MdOutlineKeyboardArrowDown /></span>
            </Link>
            <ul className='relative accordion-collapse collapse' id='collapseSidenavSecEx5' aria-labelledby='sidenavSecEx5' data-bs-parent='#sidenavSecExample'>
              <li className='relative'>
                <Link to='/app/listcampaign' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>List Campaigns</Link>
              </li>
            </ul>
          </li>
          <li className='relative' id='sidenavSecEx11'>
            <Link to='#' className='flex items-center text-sm py-4 px-6 xl:h-10 2xl:h-12 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out cursor-pointer' data-mdb-ripple='true' data-mdb-ripple-color='primary' data-bs-toggle='collapse' data-bs-target='#collapseSidenavSecEx11' aria-expanded='false' aria-controls='collapseSidenavSecEx11'>
              <span className='w-4 h-4 mr-2'><IoDocuments /></span>
              <span>KYC Documents</span>
              <span className='w-3 h-3 ml-auto mb-2 text-xl'><MdOutlineKeyboardArrowDown /></span>
            </Link>
            <ul className='relative accordion-collapse collapse' id='collapseSidenavSecEx11' aria-labelledby='sidenavSecEx11' data-bs-parent='#sidenavSecExample'>
              <li className='relative'>
                <Link to='/app/allkycuserdocuments' className='flex items-center text-xs py-4 pl-12 pr-6 h-3 overflow-hidden text-ellipsis whitespace-nowrap rounded hover:text-[#8144E5] hover:bg-[#f2ecfc] transition duration-300 ease-in-out' data-mdb-ripple='true' data-mdb-ripple-color='primary'>User Kyc Documents</Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AdvertisorNav;
