import Topnav from './components/Nav/TopBar';
import Sidebar from './components/Nav/SideBar';

import Home from './pages/Home';
import Login from './pages/Login';
import Unauthorised from './pages/Unauthorised';
import Missing from './pages/Missing'

import MyProfile from './pages/Profile/MyProfile';
import ListUsers from './pages/Users/ListUsers';
import PendingUsers from './pages/Users/PendingUsers'
import AddUser from './pages/Users/AddUser';
import ViewUser from './pages/Users/ViewUser';
import ListCategories from './pages/Adverts/ListCategories';
import AddCategory from './pages/Adverts/AddCategory';
import UpdateCategory from './pages/Adverts/UpdateCategory';
import ListAdvert from './pages/Adverts/ListAdvert';
import PendingAdverts from './pages/Adverts/PendingAdverts'
import CreateOrgAdvert from './pages/Adverts/CreateOrgAdvert';
import CreateAdvert from './pages/Adverts/CreateAdvert';
import ViewAdvert from './pages/Adverts/ViewAdvert';
import UpdateAdvertMedia from './pages/Adverts/UpdateAdvertMedia';
import UpdateAdvert from './pages/Adverts/UpdateAdvert'
import CreateCurrency from './pages/Countries/CreateCurrency';
import ListCurrencies from './pages/Countries/ListCurrencies';
import ListCountries from './pages/Countries/ListCountries';
import CreateCounty from './pages/Countries/CreateCounty';
import ListCampaign from './pages/AdvertCampaign/ListCampaigns';
import CreateCampaign from './pages/AdvertCampaign/CreateCampaign';
import ViewCampaign from './pages/AdvertCampaign/ViewCampaign';
import ListCustomer from './pages/AdConsumerCustomers/ListCustomers';
import AddCustomer from './pages/AdConsumerCustomers/AddCustomer';
import ListRecommenderApp from './pages/RecommenderApps/ListRecommenderApps';
import ListOrganisations from './pages/Organisation/ListOrganisations';
import MyOrganisation from './pages/Organisation/MyOrganisation';
import AddOrganisation from './pages/Organisation/AddOrganisation';
import UpdateOrganisation from './pages/Organisation/UpdateOrganisation';
import ViewOrganisation from './pages/Organisation/ViewOrganisation';
import ViewMyOrganisation from './pages/Organisation/ViewMyOrganisation'
import GetOrganisationMember from './pages/Organisation/GetOrganisationMembers';
import AddMemberInvite from './pages/Organisation/AddMemberInvite'
import AddOrganisationMember from './pages/Organisation/AddOrganisationMember';
import ViewOrgDocument from './pages/Organisation/ViewOrgDocument';
import UpdateOrgDocument from './pages/Organisation/UpdateOrgDocument';
import ListAdViews from './pages/UserAdInteraction/ListAdViews';
import ListAbuseReport from './pages/UserAdInteraction/ListAbuseReport';
import ListAdvertViewLikes from './pages/UserAdInteraction/ListAdvertViewLikes';
import ListAdvertRecommendation from './pages/AdvertRecommendation/ListAdvertRecommendation';

import CreateOrgDocument from './pages/KycDocuments/CreateOrgDocument';
import ListAllDocuments from './pages/KycDocuments/ListAllDocuments';
import ListUserKycDocuments from './pages/KycDocuments/ListUserKycDocuments';
import ViewWallet from './pages/Wallet/ViewWallet';

import { useStateContext } from './components/Context/ContextProvider';
import AuthToken from './components/Context/AuthToken';
import ProtectedRoute from './components/Context/ProtectedRoute';
import AdvertiserRoute from './components/Context/AdvertiserRoute';
import { Routes, Route } from 'react-router-dom';
import axios from './api/api';
import { useEffect, useState } from 'react';

const Layout = () => {

  const { activeMenu } = useStateContext();
  const { setAccessToken } = AuthToken();
  const [user, setUser] = useState({});
  const [userProfile, setUserProfile] = useState({});


  // getting logged in advertiser
  useEffect(() => {
    const getUserById = async () => {
      try {
        const response = await axios.get('/users/me');
        setUser(response.data);
        setUserProfile(response.data.kyc_profile);
      } catch (error) {
        console.error(error);
      }
    };
    getUserById();
  }, []);
  
  const token = window.localStorage.getItem('accessToken');
  const currentuser = JSON.parse(token)?.data.user;
  
  if (!currentuser) {
    return <Login setAccessToken={setAccessToken} />;
  }


  return (
    <main>
      <div className='flex relative'>
        {activeMenu
          ? (
            <div className='w-72 xl:w-60 2xl:w-72 fixed'>
              <Sidebar />
            </div>
            )
          : (
            <div>
              <Sidebar />
            </div>
            )}
        <div
          className={
              activeMenu
                ? 'min-h-screen md:ml-72 xl:ml-60 2xl:ml-72 w-full'
                : 'w-full min-h-screen flex-2'
            }
        >
          <div>
            <Topnav />
          </div>
          {user.role === 'ADVERTISER' && userProfile.verification_status === 'PENDING' ? (
            <MyProfile/>
          ) : (
            <div>
              <Routes>
                {/* Public Routes  */}
                <Route path='/dashboard' element={<Home />} />
                <Route path='/my-profile' element={<MyProfile />} />
                <Route path='/unauthorised' element={<Unauthorised />} />
                <Route path='*' element={<Missing />} />

                {/* Admin Only Routes */}
                <Route path='/listusers' element={<ProtectedRoute><ListUsers /></ProtectedRoute>} />
                <Route path='/pendingusers' element={<ProtectedRoute><PendingUsers /></ProtectedRoute>} />
                <Route path='/adduser' element={<ProtectedRoute><AddUser /></ProtectedRoute>} />
                <Route path='/listusers/:id' element={<ProtectedRoute><ViewUser /></ProtectedRoute>} />
                <Route path='/createcurrency' element={<ProtectedRoute><CreateCurrency /></ProtectedRoute>} />
                <Route path='/listcurrencies' element={<ProtectedRoute><ListCurrencies /></ProtectedRoute>} />
                <Route path='/listcountries' element={<ProtectedRoute><ListCountries /></ProtectedRoute>} />
                <Route path='/createcounty' element={<ProtectedRoute><CreateCounty /></ProtectedRoute>} />
                <Route path='/listcustomer' element={<ProtectedRoute><ListCustomer /></ProtectedRoute>} />
                <Route path='/addcustomer' element={<ProtectedRoute><AddCustomer /></ProtectedRoute>} />
                <Route path='/listrecommenderapp' element={<ProtectedRoute><ListRecommenderApp /></ProtectedRoute>} />
                <Route path='/listadviews' element={<ProtectedRoute><ListAdViews /></ProtectedRoute>} />
                <Route path='/listabusereport' element={<ProtectedRoute><ListAbuseReport /></ProtectedRoute>} />
                <Route path='/listadvertlikes' element={<ProtectedRoute><ListAdvertViewLikes /></ProtectedRoute>} />
                <Route path='/listadvertrecommendation' element={<ProtectedRoute><ListAdvertRecommendation /></ProtectedRoute>} />
                <Route path='/alldocuments' element={<ProtectedRoute><ListAllDocuments /></ProtectedRoute>} />
                <Route path='/listorganisations' element={<ProtectedRoute><ListOrganisations /></ProtectedRoute>} />
                <Route path='/addcategory' element={<ProtectedRoute><AddCategory /></ProtectedRoute>} />
                <Route path='/pendingadverts' element={<ProtectedRoute><PendingAdverts /></ProtectedRoute>} />
                <Route path='/vieworganisation/:id' element={<AdvertiserRoute><ViewOrganisation /></AdvertiserRoute>} />

                {/* Advertisers and Admin Routes */}
                <Route path='/listcategories' element={<AdvertiserRoute><ListCategories /></AdvertiserRoute>} />
                <Route path='/updatecategory/:id' element={<AdvertiserRoute><UpdateCategory /></AdvertiserRoute>} />
                <Route path='/listadvert' element={<AdvertiserRoute><ListAdvert /></AdvertiserRoute>} />
                <Route path='/createadvert/:id' element={<AdvertiserRoute><CreateOrgAdvert /></AdvertiserRoute>} />
                <Route path='/viewadvert/:id' element={<AdvertiserRoute><ViewAdvert /></AdvertiserRoute>} />
                <Route path='/advert' element={<AdvertiserRoute><CreateAdvert /></AdvertiserRoute>} />
                <Route path='/updateadvertmedia/:id' element={<AdvertiserRoute><UpdateAdvertMedia /></AdvertiserRoute>} />
                <Route path='/updateadvert/:id' element={<AdvertiserRoute><UpdateAdvert /></AdvertiserRoute>} />
                <Route path='/listcampaign' element={<AdvertiserRoute><ListCampaign /></AdvertiserRoute>} />
                <Route path='/createcampaign/:id' element={<AdvertiserRoute><CreateCampaign /></AdvertiserRoute>} />
                <Route path='/viewcampaign/:id' element={<AdvertiserRoute><ViewCampaign /></AdvertiserRoute>} />
                <Route path='/myorganisations' element={<AdvertiserRoute><MyOrganisation /></AdvertiserRoute>} />
                <Route path='/addorganisation' element={<AdvertiserRoute><AddOrganisation /></AdvertiserRoute>} />
                <Route path='/updateorganisation/:id' element={<AdvertiserRoute><UpdateOrganisation /></AdvertiserRoute>} />
                <Route path='/viewmyorganisation/:id' element={<AdvertiserRoute><ViewMyOrganisation /></AdvertiserRoute>} />
                <Route path='/getorganisationmember/:id/member' element={<AdvertiserRoute><GetOrganisationMember /></AdvertiserRoute>} />
                <Route path='/addorganisationmember/:id' element={<AdvertiserRoute><AddOrganisationMember /></AdvertiserRoute>} />
                <Route path='/addorganisationmemberinvite/:id' element={<AdvertiserRoute><AddMemberInvite /></AdvertiserRoute>} />
                <Route path='/updatedocument/:id' element={<AdvertiserRoute><UpdateOrgDocument /></AdvertiserRoute>} />
                <Route path='/viewdocument/:id' element={<AdvertiserRoute><ViewOrgDocument /></AdvertiserRoute>} />
                <Route path='/createdocument/:id' element={<AdvertiserRoute><CreateOrgDocument /></AdvertiserRoute>} />
                <Route path='/allkycuserdocuments' element={<AdvertiserRoute><ListUserKycDocuments /></AdvertiserRoute>} />

                {/* Payment Routes */}
                <Route path='/viewwallet/:id' element={<ViewWallet />} />
              </Routes>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default Layout;
