import React, { useState, useEffect, useCallback } from 'react';
import Pagination from '../Pagination';
import axios from '../../api/api';
import { MdOutlineBlock, MdOutlineClose } from 'react-icons/md';
import { IoCheckmarkSharp } from 'react-icons/io5';
import Loader from '../Loader';
import { toast } from 'react-toastify'

const ListRecommenderApps = () => {

  const [recommenderapp, setRecommenderapp] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [count, setCount] = useState(0)
  const [include_inactive, setInclude_inactive] = useState('');


  // Fetch All recommender apps
  const getRecommenderapp = useCallback(async (offset, limit) => {
    try {
      let query = `?offset=${offset}&limit=${limit}`

      if(include_inactive) {
        query += `&include_inactive=${include_inactive}`
      }

      const response = await axios.get(`/recommender-apps/${query}`);
      setRecommenderapp(response.data.items);
      setCount(response.data.count);
      setLoading(false);
      setError(null);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (!error.response) {
        setError('Network error! Check your connection.');
      } else if (error.response.status >= 500) {
        setError('Server error! Please try again later.');
      } else {
        setError('An unexpected error occurred.');
      }
    }
  }, [include_inactive]);

  useEffect(() => {
    getRecommenderapp((currentPage - 1) * recordsPerPage, recordsPerPage);
  }, [currentPage, recordsPerPage, getRecommenderapp, include_inactive]);

  // enable an app
  const enableApp = async (app_name) => {
    const confirmed = window.confirm('Are you sure you want to enable this app');
    if (confirmed) {
      try {
        await axios.put(`/recommender-apps/${app_name}/enable`);
        toast.success('App Enabled');
        getRecommenderapp((currentPage - 1) * recordsPerPage, recordsPerPage);
      } catch (error) {
        toast.error('Failed to enable app');
        console.log(error);
      }
    }
  };

  // disable an app
  const disableApp = async (app_name) => {
    const confirmed = window.confirm('Are you sure you want to disable this app');
    if (confirmed) {
      try {
        await axios.put(`/recommender-apps/${app_name}/disable`);
        toast.info('App Disabled');
        getRecommenderapp((currentPage - 1) * recordsPerPage, recordsPerPage);
      } catch (error) {
        toast.error('Failed to disable app');
        console.log(error);
      }
    }
  };

  return (
    <div className='mx-auto p-4'>
      <div className='bg-white rounded-lg p-4'>
        <div className='flex flex-wrap items-center justify-between py-3'>
          <h6 className='text-[#6735b7] font-bold'>Showing {count} Apps</h6>
          <div>
              <select
                className='px-3 py-1 border bg-[#f2ecfc] border-slate-300 rounded-md focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] focus:ring-1'
                value={include_inactive}
                onChange={(e) => setInclude_inactive(e.target.value)}
              >
                <option value=''>Include Inactive</option>
                <option value='true'>Yes</option>
                <option value='false'>No</option>
              </select>
            </div>
        </div>
        {loading
          ? (
            <div className='flex items-center justify-center h-[70vh] bg-[#f2ecfc]'>
              <Loader />
            </div>
            )
          : error
            ? (
              <div className='bg-[#f2ecfc] h-[70vh] grid place-items-center'>
                <div className='grid place-items-center text-red-600 p-4'>
                  <h3><MdOutlineBlock /></h3>
                  <span>{error}</span>
                </div>
              </div>
              )
            : (
              <div className='overflow-x-auto rounded-lg'>
                {recommenderapp.length > 0
                  ? (
                    <table className='w-full text-justify table-auto'>
                      <thead>
                        <tr className='border-b border-slate-500'>
                          <th className='py-3 px-6'>No</th>
                          <th className='py-3 px-6'>App Name</th>
                          <th className='py-3 px-6'>Created By Id</th>
                          <th className='py-3 px-6'>Status</th>
                          <th className='py-3 px-6'>Last Time Used</th>
                          <th className='py-3 px-6'>Created At</th>
                          <th className='py-3 px-6'>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {recommenderapp.map((app) => (
                          <tr key={app.id}>
                            <td className='py-2 px-6'>{app.id}</td>
                            <td className='py-2 px-6'>{app.app_name}</td>
                            <td className='py-2 px-6'>{app.created_by_user_id}</td>
                            <td className='py-2 px-6'>{app.is_active === true
                              ? (
                                <p className='bg-blue-300 text-white text-center font-light rounded-full'>Active</p>
                                )
                              : (
                                <p className='bg-red-300 text-white text-center font-light rounded-full'>Inactive</p>
                                )}
                            </td>
                            <td className='py-2 px-6'>{app.time_last_used ? (new Date(app.time_last_used).toISOString().replace('T', ' ').slice(0, 19)) : ('...')}</td>
                            <td className='py-2 px-6'>{new Date(app.time_created).toISOString().replace('T', ' ').slice(0, 19)}</td>
                            <td className='p-2'>
                              {app.is_active === true ? (
                                <button onClick={() => disableApp(app.app_name)} className='text-orange-600 hover:text-orange-500 flex items-center'><span><MdOutlineClose /></span>Disable</button>
                              ) : (
                                <button onClick={() => enableApp(app.app_name)} className='text-green-600 hover:text-green-500 flex items-center'><span><IoCheckmarkSharp /></span>Enable</button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    )
                  : (
                    <div className='bg-[#f2ecfc] h-full grid place-items-center'>
                      <div className='grid place-items-center'>
                        <h3><MdOutlineBlock /></h3>
                        <h4>No Data</h4>
                      </div>
                    </div>
                    )}
              </div>
              )}
        <div className='flex flex-wrap items-center justify-between mt-4 gap-4'>
          <div>
            <span className='mr-2'>Records per page:</span>
            <select
              className='px-3 py-1 border bg-[#f2ecfc] border-slate-300 rounded-md focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] focus:ring-1'
              value={recordsPerPage}
              onChange={(e) => {
                setRecordsPerPage(parseInt(e.target.value, 10));
                setCurrentPage(1);
              }}
            >
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='50'>50</option>
              <option value='75'>75</option>
              <option value='100'>100</option>
            </select>
          </div>
          <Pagination
            nPages={Math.ceil(count / recordsPerPage)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default ListRecommenderApps;
