import React, { useState, useEffect, useCallback } from 'react';
import Pagination from '../Pagination';
import axios from '../../api/api';
import { MdOutlineBlock } from 'react-icons/md';
import Loader from '../Loader';

const ListUserKycDocuments = () => {

  const [listUserDocuments, setListUserDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(15);
  const [include_deleted, setInclude_deleted] = useState('');
  const [verified_only, setVerified_only] = useState('');
  const [pending_verification_only, setPending_verification_only] = useState('');

  // getting current user
  // const currentUser = window.localStorage.getItem('token');
  // const user_id = JSON.parse(currentUser).data.user.id;


  // Fetch All user kyc documents
  const getuserdocuments = useCallback(async (offset, limit) => {
    try {
      let query = `?offset=${offset}&limit=${limit}`
      if (include_deleted) {
        query += `&include_deleted=${include_deleted}`
      }
      if (verified_only) {
        query += `&verified_only=${verified_only}`
      }
      if (pending_verification_only) {
        query += `&pending_verification_only=${pending_verification_only}`
      }
      const response = await axios.get(`/kyc-documents/${query}`);
      setListUserDocuments(response.data.items);
      setLoading(false);
      setError(null);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (!error.response) {
        setError('Network error! Check your connection.');
      } else if (error.response.status >= 500) {
        setError('Server error! Please try again later.');
      } else {
        setError('An unexpected error occurred.');
      }
    }
  }, [include_deleted, verified_only, pending_verification_only]);
  
  useEffect(() => {
    getuserdocuments((currentPage - 1) * recordsPerPage, recordsPerPage);
  }, [currentPage, recordsPerPage, getuserdocuments,include_deleted, verified_only, pending_verification_only]);


  return (
    <div className='mx-auto p-4'>
      <div className='bg-white rounded-lg p-4'>
        <div className='flex flex-wrap items-center justify-between py-3'>
          <h5 className='text-[#8144E5] font-bold'>Showing {listUserDocuments.length} Users KYC Documents</h5>
          <div className='flex items-center space-x-2'>
            <div>
              <select
                className='px-3 py-1 border bg-[#f2ecfc] border-slate-300 rounded-md focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] focus:ring-1'
                value={include_deleted}
                onChange={(e) => setInclude_deleted(e.target.value)}
              >
                <option value=''>Include Deleted</option>
                <option value='true'>Yes</option>
                <option value='false'>No</option>
              </select>
            </div>
            <div>
              <select
                className='px-3 py-1 border bg-[#f2ecfc] border-slate-300 rounded-md focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] focus:ring-1'
                value={verified_only}
                onChange={(e) => setVerified_only(e.target.value)}
              >
                <option value=''>Verified Only</option>
                <option value='true'>Yes</option>
                <option value='false'>No</option>
              </select>
            </div>
            <div>
              <select
                className='px-3 py-1 border bg-[#f2ecfc] border-slate-300 rounded-md focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] focus:ring-1'
                value={pending_verification_only}
                onChange={(e) => setPending_verification_only(e.target.value)}
              >
                <option value=''>Pending Verification Only</option>
                <option value='true'>Yes</option>
                <option value='false'>No</option>
              </select>
            </div>
          </div>
        </div>
        {loading
          ? (
            <div className='flex items-center justify-center h-[70vh] bg-[#f2ecfc]'>
              <Loader />
            </div>
            )
          : error
            ? (
              <div className='bg-[#f2ecfc] h-[70vh] grid place-items-center'>
                <div className='grid place-items-center text-red-600 p-4'>
                  <h3><MdOutlineBlock /></h3>
                  <span>{error}</span>
                </div>
              </div>
              )
            : (
              <div className='overflow-x-auto rounded-lg'>
                {listUserDocuments.length > 0
                  ? (
                    <table className='w-full text-justify table-auto'>
                      <thead>
                        <tr className='border-b border-slate-500'>
                          <th className='py-3 px-6'>No</th>
                          <th className='py-3 px-6'>Name</th>
                          <th className='py-3 px-6'>Doc Type</th>
                          <th className='py-3 px-6'>Description</th>
                          <th className='py-3 px-6'>Media Type</th>
                          <th className='py-3 px-6'>Comment</th>
                          <th className='py-3 px-6'>Staff Comment</th>
                          <th className='py-3 px-6'>Verification</th>
                          <th className='py-3 px-6'>Status</th>
                          <th className='py-3 px-6'>Created At</th>
                          <th className='py-3 px-6'>Updated At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listUserDocuments.map((document) => (
                          <tr key={document.id}>
                            <td className='py-2 px-6'>{document.id}</td>
                            <td className='py-2 px-6'>{document.name}</td>
                            <td className='py-2 px-6'>{document.document_type}</td>
                            <td className='py-2 px-6'>{document.description}</td>
                            <td className='py-2 px-6'>{document.media_type}</td>
                            <td className='py-2 px-6'>{document.user_comments}</td>
                            <td className='py-2 px-6'>{document.staff_comments}</td>
                            <td className='py-2 px-6'>
                              {document.verification_status === 'VERIFIED' ? (
                                <span className='bg-[#D9F8EB] text-[#2A8B4F] px-4 py-1 font-bold rounded-full'>Verified</span>
                              ) : document.verification_status === 'PENDING' ?  (
                                <span className='bg-[#D9E7FA] text-[#215CB1] font-bold px-3 py-1 rounded-full'>Pending</span>
                              ) : (
                                <span className='bg-[#FDE6D8] text-[#AC5A2B] font-bold px-3 py-1 rounded-full'>Rejected</span>
                              )}
                            </td>
                            <td className='py-2 px-6'>
                              {document.is_deleted === true
                                ? (
                                  <span className='bg-[#FDE6D8] text-[#AC5A2B] px-4 py-1 font-bold rounded-full'>Rejected</span>
                                  )
                                : (
                                  <span className='bg-[#D9F8EB] text-[#2A8B4F] px-4 py-1 font-bold rounded-full'>Approved</span>
                                  )}
                            </td>
                            <td className='py-2 px-6'>{new Date(document.time_created).toISOString().replace('T', ' ').slice(0, 19)}</td>
                            <td className='py-2 px-6'>{new Date(document.time_updated).toISOString().replace('T', ' ').slice(0, 19)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    )
                  : (
                    <div className='bg-[#f2ecfc] h-full grid place-items-center'>
                      <div className='grid place-items-center'>
                        <h3><MdOutlineBlock /></h3>
                        <h4>No Data</h4>
                      </div>
                    </div>
                    )}
              </div>
              )}
        <div className='flex flex-wrap items-center justify-between mt-4 gap-4'>
          <div>
            <span className='mr-2'>Records per page:</span>
            <select
              className='px-3 py-1 border bg-[#f2ecfc] border-slate-300 rounded-md focus:outline-none focus:border-[#8144E5] focus:ring-[#8144E5] focus:ring-1'
              value={recordsPerPage}
              onChange={(e) => {
                setRecordsPerPage(parseInt(e.target.value, 10));
                setCurrentPage(1);
              }}
            >
              <option value='15'>15</option>
              <option value='25'>25</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </select>
          </div>
          <Pagination
            nPages={Math.ceil(listUserDocuments / recordsPerPage)}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
};

export default ListUserKycDocuments;
